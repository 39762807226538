import React from "react";
import { OTSubscriber, OTSubscriberRef } from "opentok-react";
import { Warning, AvTimer, NotificationImportantOutlined } from "@material-ui/icons";
import styled from "styled-components";
import moment from "moment";

import { checkOpentokRole } from "src/utils/checkOpentokRole";

import CamOnIcon from "../../assets/camOnIcon.svg";
import CamOffIcon from "../../assets/camOffIcon.svg";
import AudioOnIcon from "../../assets/sound-off-icon.svg";
import AudioOffIcon from "../../assets/volumeIconOff.svg";
import DeclineBtn from "../../assets/declineButton.svg";
import NoUserIcon from "../../assets/no-user-icon.svg";
import { fullHeight } from "src/utils/constants";
import CountdownTimer from "src/utils/CountdownTimer";
import { CallTitleInfo } from "./CallTitleInfo";
import { CallTypes, OpentokSignals } from "src/types";
import { Feature, featureDetector } from "src/utils/featureDetector";
import { useVideoRecorder } from "src/hooks";

interface Props {
  subscriber: any;
  setSubscribersToRender: any;
  sessionRef: any;
  //   isGroupCall: boolean;
  isCountdownStart: boolean;
  setIsCountdownStart: any;
  countdownTime: any;
  selectedCall: any;
  handleDeclineCall: any;
  handlePayAttention: () => void;
}

export function Subscriber({
  subscriber,
  setSubscribersToRender,
  sessionRef,
  //   isGroupCall,
  isCountdownStart,
  setIsCountdownStart,
  countdownTime,
  selectedCall,
  handleDeclineCall,
  handlePayAttention,
}: Props) {
  const { startRecording, stopRecording } = useVideoRecorder();
  const subscriberRef = React.useRef<({ node: any } & OTSubscriberRef) | null>(null);

  const { availableFeatures } = featureDetector(
    selectedCall.call?.app_version,
    selectedCall.call?.platform
  );

  // const hasRole = React.useCallback(checkOpentokRole, []);

  const isSpeaker = React.useMemo(
    () => checkOpentokRole(subscriber.stream.connection.data, "speaker"),
    [subscriber.stream.connection.data]
  );


  const handleShouldUpdate = React.useCallback(function shouldUpdate(
    currentVersion: string | undefined,
    latestVersion: string | undefined
  ) {
    if (!currentVersion || !latestVersion) {
      console.warn("currentVersion or latestVersion haven't been provided");
      return false;
    }

    const parts1 = currentVersion.split(".").map(Number);
    const parts2 = latestVersion.split(".").map(Number);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const part1 = parts1[i] || 0;
      const part2 = parts2[i] || 0;

      if (part1 < part2) {
        return true; // Update needed, latest version is greater
      } else if (part1 > part2) {
        return false; // No update needed, current version is greater
      }
    }

    return false; // No update needed, versions are equal
  },
  []);

  const userAudioHandler = () => {
    setSubscribersToRender((prevState: any) => {
      return prevState.map((el: any) =>
        el.stream.id === subscriber.stream.id
          ? {
              ...el,
              isAudioOn: !el.isAudioOn,
            }
          : el
      );
    });

    sessionRef.current.sessionHelper.session.signal({
      data: subscriber.stream.id,
      type: subscriber.isAudioOn ? OpentokSignals.BlockMicro : OpentokSignals.UnblockMicro,
    });
  };

  const userVideoHandler = () => {
    setSubscribersToRender((prevState: any) => {
      return prevState.map((el: any) =>
        el.stream.id === subscriber.stream.id
          ? {
              ...el,
              isCameraOn: !el.isCameraOn,
            }
          : el
      );
    });

    sessionRef.current.sessionHelper.session.signal({
      data: subscriber.stream.id,
      type: subscriber.isCameraOn ? OpentokSignals.BlockCamera : OpentokSignals.UnblockCamera,
    });

    // if (subscriber.isCameraOn && !subscriber.isAmsaanPro && isGroupCall) {
    //   sessionRef.current.sessionHelper.session.signal({
    //     data: subscriber.stream.id,
    //     type: "removeSelectedUser",
    //   });
    // }

    // setIsOffSpeaker(subscriber.isCameraOn && isGroupCall && subscriber.isAmsaanPro);
  };

  if (isSpeaker) {
    return (
      <SpeakerContainer>
        <OTSubscriber
          //   eventHandlers={{
          //     disconnected: (event) => {
          //       console.log("disconnected", event);
          //       // setSubscribersToRender((prev) =>
          //       //   prev.filter((el) => el.stream.id !== event.target.stream.id)
          //       // );
          //       // sessionRef.current.sessionHelper.session.unsubscribe(
          //       //   event.target.stream.connection
          //       // );
          //     },
          //     connected: (event) => {
          //       console.log("connected", event);
          //     },
          //   }}
          key={subscriber.stream.id}
          stream={subscriber.stream}
          session={sessionRef.current.sessionHelper.session}
          properties={{
            width: 120,
            height: 120,
            fitMode: "cover",
            // subscribeToAudio: subscriber.isAudioOn,
            subscribeToAudio: true,
            style: { buttonDisplayMode: "off" },
          }}
        />
        <ControlsContainer>
          <ControlButton onClick={userAudioHandler}>
            <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
          </ControlButton>

          <ControlButton onClick={userVideoHandler}>
            <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
          </ControlButton>
        </ControlsContainer>
      </SpeakerContainer>
    );
  }

  return (
    <FullScreenContainer fullHeight={fullHeight} key={subscriber.stream.id}>
      <CountdownButton
        disabled={isCountdownStart}
        onClick={() => {
          setIsCountdownStart(true);
        }}
        isCountdownStart={isCountdownStart}
      >
        {isCountdownStart ? (
          <Warning width={"1rem"} height={"1rem"} />
        ) : (
          <AvTimer width={"1rem"} height={"1rem"} />
        )}
        {isCountdownStart ? (
          <CountdownTimer
            countdownTime={countdownTime}
            options={{ minutes: true, seconds: true }}
          />
        ) : null}
      </CountdownButton>
      <div style={{ position: "absolute", top: 16, zIndex: 200 }}>
        <CallTitleInfo handleShouldUpdate={handleShouldUpdate} sessionData={selectedCall} />
      </div>
      {subscriber.isCameraOn ? (
        <>
          <OTSubscriber
            ref={subscriberRef}
            eventHandlers={{
              disconnected: (event) => {
                console.log("disconnected", event);
                if (selectedCall.type === CallTypes.CLIENT_CALL) {
                  stopRecording();
                }
              },
              connected: async (event: any) => {
                console.log("connected", event);
                if (selectedCall.type === CallTypes.CLIENT_CALL) {
                  const videoEl = await event.target.videoElement();
                  const fallbackVideoEl =
                    subscriberRef.current?.node.querySelector(".OT_video-element");

                  startRecording(
                    videoEl || fallbackVideoEl,
                    `subscriber-${selectedCall.session_id}-${moment
                      .unix(selectedCall.asnwered_at || selectedCall.started_at)
                      .format("YYYY-MM-DD HH:mm:ss")}`
                  );
                }
              },
              destroyed: async (event) => {
                console.log("destroyed", event);
                if (selectedCall.type === CallTypes.CLIENT_CALL) {
                  stopRecording();
                }
              },
            }}
            key={subscriber.stream.id}
            stream={subscriber.stream}
            session={sessionRef?.current?.sessionHelper?.session}
            //@ts-ignore
            style={{
              width: "100%",
              height: "100%",
            }}
            properties={{
              width: "100%",
              fitMode: "contain",
              height: fullHeight,
              subscribeToAudio: subscriber.isAudioOn,
              style: { buttonDisplayMode: "off" },
            }}
          />
        </>
      ) : (
        <PlaceholderWrapper fullHeight={fullHeight}>
          <img
            src={NoUserIcon}
            alt="no user"
            style={{
              width: "20%",
              height: "20%",
              minHeight: 75,
              minWidth: 75,
            }}
          />
        </PlaceholderWrapper>
      )}

      <ButtonsGroup>
        {Boolean(selectedCall.call.connect_pro_web_client_id) ||
        availableFeatures.includes(Feature.PAY_ATTENTION) ? (
          <Button
            onClick={handlePayAttention}
            style={{
              color: "#fff",
            }}
          >
            <NotificationImportantOutlined width={58} height={58} color="inherit" />
          </Button>
        ) : null}
        <Button onClick={userAudioHandler}>
          <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
        </Button>

        <Button onClick={userVideoHandler}>
          <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
        </Button>

        <Button onClick={handleDeclineCall}>
          <img src={DeclineBtn} alt="Decline" />
        </Button>
      </ButtonsGroup>
    </FullScreenContainer>
  );
}

const SpeakerContainer = styled.div({
  position: "absolute",
  width: 120,
  height: 120,
  top: 44,
  right: 54,
  zIndex: 100,
  borderRadius: "50%",
  overflow: "hidden",
});

const ControlsContainer = styled.div({
  position: "absolute",
  bottom: 4,
  left: "50%",
  transform: "translate(-50%,0)",
  zIndex: 100,
  display: "flex",
  gap: 4,
});

const ControlButton = styled.button({
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  padding: 0,
});

const FullScreenContainer = styled.div<{ fullHeight: string }>(({ fullHeight }) => ({
  width: "100%",
  height: fullHeight,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#000",
}));

const CountdownButton = styled.button<{ isCountdownStart: boolean }>(({ isCountdownStart }) => ({
  display: "flex",
  alignItems: "center",
  gap: 4,
  position: "absolute",
  top: 16,
  left: 16,
  borderRadius: 12,
  border: "1px solid",
  borderColor: "orange",
  color: "orange",
  backgroundColor: "#fff",
  padding: 8,
  zIndex: 200,
  cursor: isCountdownStart ? "auto" : "pointer",
}));

const PlaceholderWrapper = styled.div<{ fullHeight: string }>(({ fullHeight }) => ({
  width: "100%",
  maxWidth: 600,
  height: fullHeight,
  backgroundColor: "#000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Button = styled.button({
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  margin: "0 10px",
});

const ButtonsGroup = styled.div({
  position: "absolute",
  bottom: 15,
  // left: 0,
  zIndex: 100,
  // width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "4px",
  borderRadius: "4px",
  transition: "background-color 0.3s", 
  backgroundColor: "rgba(0,0,0,0.3)", 

  "&:hover": {
    backgroundColor: "rgba(0,0,0,1)", 
  },
});
