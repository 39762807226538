import React, { useCallback, useRef, useState } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import { CallActionTypes } from "./CallActionsTypes";
import io from "socket.io-client";
import { AuthService } from "../../services/AuthService";
import useSound from "use-sound";
import callSound from "../../assets/call.wav";
// import { INTERPRETER_STATE } from "../../constants/defaultConstants";
import { CallService } from "../../services/CallService";
import { CallReason, CallStatus, InterpreterStatus } from "../../types";
import { areArraysSimilarByStatus } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { InterpreterService } from "src/services/InterpreterService";
import ReasonModal from "src/components/ReasonModal/ReasonModal";
import { useIsOpen } from "src/hooks";
// import useSearchParamControls from "src/hooks/useSearchParamsControl";
import {
  // selectShouldDisplayReasonForm,
  setSessionIdToSubmit,
  setShouldDisplayReasonForm,
  setUserReasonSelected,
} from "../Reasons";
import { usePopupContext } from "src/providers/PopupProvider";
import { useTranslation } from "react-i18next";
import { selectCurrentGroupCall } from "../GroupCall";

const initialState = {
  selectedCall: {
    session_id: "",
    token: "",
  },
  incomingCalls: [],
  historyCalls: [],
  selectedHistoryCall: {},
  contacts: [],
  selectedContact: {},
  othersCalls: [],
  priorityCalls: [],
  questioner: {
    status: "",
    userId: "",
  },
  operatorStatus: InterpreterStatus.ONLINE,
  operators: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CallActionTypes.PREVIEW_CALL: {
      if (state.selectedCall?.session_id?.length === 0) {
        return {
          ...state,
          selectedCall: action.payload,
        };
      } else {
        return state;
      }
    }
    case CallActionTypes.TAKE_CALL: {
      const newIncoming = state.incomingCalls.filter(
        (el) => String(el.session_id) !== String(state.selectedCall.session_id)
      );

      return {
        ...state,
        selectedCall: { ...state.selectedCall, status: CallStatus.ANSWERED },
        incomingCalls: newIncoming,
      };
    }
    case CallActionTypes.UPDATE_CURRENT_CALL: {
      if (!state.selectedCall) return state;
      return {
        ...state,
        selectedCall: { ...state.selectedCall, answered_at: action.payload.answered_at },
      };
    }
    case CallActionTypes.DELETE_CALL: {
      // const newIncoming = state.incomingCalls.filter((el) => el.session_id !== action.payload);

      // return {
      //   ...state,
      //   incomingCalls: newIncoming,
      //   selectedCall:
      //     state.selectedCall.session_id === action.payload
      //       ? initialState.selectedCall
      //       : state.selectedCall,
      // };

      return {
        ...state,
        othersCalls: state.othersCalls.filter((call) => call.session_id !== action.payload),
      };
    }
    case CallActionTypes.END_CALL: {
      const newIncoming = state.incomingCalls.filter(
        (el) => el.session_id !== state.selectedCall.session_id
      );

      return {
        ...state,
        incomingCalls: newIncoming,
        selectedCall: initialState.selectedCall,
      };
    }
    case CallActionTypes.SET_INCOMING_CALLS: {
      return {
        ...state,
        incomingCalls: [action.payload, ...state.incomingCalls],
      };
    }
    case CallActionTypes.CLEAR_INCOMING_CALLS: {
      return {
        ...state,
        incomingCalls: [],
      };
    }
    case CallActionTypes.SET_OTHERS_CALLS: {
      return { ...state, othersCalls: action.payload };
    }
    case CallActionTypes.PUSH_NEW_SOCKET_CALL: {
      if (state.othersCalls.some((el) => el.session_id === action.payload.session_id))
        return { ...state };

      return { ...state, othersCalls: [action.payload, ...state.othersCalls] };
    }
    case CallActionTypes.PUSH_SOCKET_CALL_BY_STARTED_AT: {
      const existingIndex = state.othersCalls.findIndex(
        (el) => el.session_id === action.payload.session_id
      );

      if (existingIndex !== -1) return { ...state };

      const updatedCalls = [...state.othersCalls];
      const insertionIndex = updatedCalls.findIndex(
        (el) => el.started_at < action.payload.started_at
      );

      if (insertionIndex === -1) {
        updatedCalls.push(action.payload);
      } else {
        updatedCalls.splice(insertionIndex, 0, action.payload);
      }

      return { ...state, othersCalls: updatedCalls };
    }

    // case CallActionTypes.SET_OTHERS_CALLS: {
    //   const newIncoming = state.incomingCalls.filter((el) => el.sessionId != action.payload);
    //   const newOthers = state.incomingCalls.find((el) => el.sessionId === action.payload);

    //   return {
    //     ...state,
    //     othersCalls: newOthers
    //       ? [...state.othersCalls, { ...newOthers, status: "reserved" }]
    //       : state.othersCalls,
    //     incomingCalls: newIncoming,
    //     selectedCall:
    //       state.selectedCall.sessionId === action.payload &&
    //       state.selectedCall.status !== CallStatus.ANSWERED
    //         ? initialState.selectedCall
    //         : state.selectedCall,
    //   };
    // }
    case CallActionTypes.GET_CONTACTS: {
      return {
        ...state,
        contacts: [...state.contacts, ...action.payload.items],
        nextPage: action.payload.currentPage !== action.payload.totalPages,
      };
    }
    case CallActionTypes.CLEAR_CONTACTS: {
      return {
        ...state,
        contacts: initialState.contacts,
        nextPage: null,
      };
    }
    case CallActionTypes.GET_CALL_HISTORY: {
      return {
        ...state,
        historyCalls: [...state.historyCalls, ...action.payload.data],
        nextPage: action.payload.next_page,
      };
    }
    case CallActionTypes.CLEAR_CALL_HISTORY: {
      return {
        ...state,
        historyCalls: [],
      };
    }
    case CallActionTypes.SET_SELECTED_CONTACT: {
      return { ...state, selectedContact: { ...action.payload } };
    }
    case CallActionTypes.SET_QUESTIONER: {
      return { ...state, questioner: action.payload };
    }
    case CallActionTypes.REMOVE_QUESTIONER: {
      return { ...state, questioner: { userId: "", status: "" } };
    }
    case CallActionTypes.SET_SELECTED_HISTORY: {
      return { ...state, selectedHistoryCall: action.payload };
    }
    case CallActionTypes.SET_OPERATOR_STATUS: {
      return { ...state, operatorStatus: action.payload };
    }
    case CallActionTypes.ADD_NEW_OPERATOR: {
      let newOperators;

      if (state.operators.find((el) => el.id === action.payload.id)) {
        newOperators = state.operators.map((el) =>
          el.id === action.payload.id ? action.payload : el
        );
      } else {
        newOperators = [...state.operators, action.payload];
      }

      return { ...state, operators: newOperators };
    }
    case CallActionTypes.CHANGE_OPERATOR_STATUS: {
      return {
        ...state,
        operators: state.operators.map((el) =>
          el.id === action.payload.userId ? { ...el, activity: action.payload.status } : el
        ),
      };
    }
    case CallActionTypes.UPDATE_CALL_ITEM: {
      const { session_id, ...payloadRest } = action.payload;
      const updatedCall = { session_id, ...payloadRest };

      const updatedCalls = state.othersCalls.map((call) =>
        call.session_id === session_id ? { ...call, ...updatedCall } : call
      );

      return {
        ...state,
        othersCalls: updatedCalls,
      };
    }
    case CallActionTypes.ADD_PRIORITY_CALL: {
      const isCallInPriority = state.priorityCalls.some((call) => call.id === action.payload.id);

      if (isCallInPriority) {
        return state;
      }

      const existingCall = state.othersCalls.find((call) => call.id === action.payload.id);
      // console.log("existingCall", existingCall);
      const newPriorityCall = existingCall
        ? { ...existingCall, ...action.payload }
        : action.payload;
      // console.log("newPriorityCall", newPriorityCall);
      return {
        ...state,
        priorityCalls: [newPriorityCall, ...state.priorityCalls],
      };
    }
    case CallActionTypes.DELETE_PRIORITY_CALL:
      return {
        ...state,
        priorityCalls: state.priorityCalls.filter((call) => call.session_id !== action.payload),
      };
    case CallActionTypes.UPDATE_PRIORITY_CALL:
      const callToUpdate = state.priorityCalls.find(
        (call) => call.session_id === action.payload.session_id
      );

      if (callToUpdate) {
        Object.assign(callToUpdate, action.payload);
      }

      return {
        ...state,
      };
    case CallActionTypes.SET_PRIORITY_CALLS: {
      return { ...state, priorityCalls: action.payload };
    }
    default:
      return state;
  }
};

export const CallContext = createContext(initialState);
export const useCallContext = () => useContext(CallContext);

export const CallProvider = ({ children }) => {
  const [callState, dispatchCall] = useReducer(reducer, initialState);
  const [stats, setStats] = useState({
    calls: 0,
    requests: 0,
    groups: 0,
    deaf:0,
    soonGroups: {},
    socket: null,
  });

  const publisher = useRef(null);
  const [publisherVideo, setPublisherVideo] = useState(true);
  const [publisherAudio, setPublisherAudio] = useState(true);
  const sessionRef = useRef(null);
  const socket = useRef(null);
  const { openPopup } = usePopupContext();
  const selectedGroupCall = useSelector(selectCurrentGroupCall);

  const [isVolumeOn, setIsVolumeOn] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const [sessionData] = useState(null);
  const [bookingModalInfo, setBookingModalInfo] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [rightTabId, setRightTabId] = useState(2);

  const [canStartCall, setCanStartCall] = useState(false);
  const [streamAvailable, setStreamAvailable] = useState(false);
  const [subscribersToRender, setSubscribersToRender] = useState([]);
  const [isOffSpeaker, setIsOffSpeaker] = useState(false);
  const [handUpCount, setHandUpCount] = useState(0);
  const flag = useRef(true);
  // const isShouldDisplayReasonForm = useSelector(selectShouldDisplayReasonForm);
  // const [isShouldDisplayReasonForm, setIsShouldDisplayReasonForm] = useState(false);
  // const isShouldDisplayReasonForm = useRef(false);
  const { t } = useTranslation();
  const [playSound, exposedData] = useSound(callSound, {
    interrupt: true,
    volume: 1,
    id: 1,
    loop: true,
  });
  const previousCallsRef = useRef([]); // need for cache array of calls
  const isAuth = AuthService.getUser();
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const {
    isOpen: isOpenSetReason,
    open: openSetReasonModal,
    close: closeSetReasonModal,
  } = useIsOpen();

  const fetchCallsData = React.useCallback(async () => {
    if (isFetching) {
      return; // If a request is already in progress, do nothing
    }

    setIsFetching(true);

    try {
      const data = await CallService.getCalls();
      const normalizeDataByNullStartedAt = data.calls.filter((call) => call.started_at);

      const isNewIncomeIncludes = normalizeDataByNullStartedAt.some(
        (el) => el.status === CallStatus.NEW
      );

      if (isNewIncomeIncludes) {
        setIsCalling(true);
      } else {
        setIsCalling(false);
      }

      if (areArraysSimilarByStatus(previousCallsRef.current, normalizeDataByNullStartedAt)) {
        return;
      }

      previousCallsRef.current = normalizeDataByNullStartedAt;

      const filteredData = normalizeDataByNullStartedAt.filter((el) => {
        if (el.reason === CallReason.CALLBACK) {
          return el.status === CallStatus.ANSWERED || el.status === CallStatus.ENDED;
        }

        return el;
      });

      const answeredCalls = filteredData.filter((call) => call.status === CallStatus.ANSWERED);

      const otherCalls = filteredData.filter((call) => call.status !== CallStatus.ANSWERED);

      dispatchCall({
        type: CallActionTypes.SET_OTHERS_CALLS,
        payload: [...otherCalls],
      });
      dispatchCall({
        type: CallActionTypes.SET_PRIORITY_CALLS,
        payload: answeredCalls,
      });
    } catch (error) {
      // Handle error
    } finally {
      setIsFetching(false); // Reset flag after request completes (either success or error)
    }
  }, [setIsCalling, dispatchCall, isFetching]);

  // const intervalFetchDataCalls = React.useMemo(
  //   () => setInterval(fetchCallsData, 2500),
  //   [fetchCallsData]
  // );

  const endCall = useCallback(() => {
    setCanStartCall(false);
    setStreamAvailable(false);
    setSubscribersToRender([]);
    setIsOffSpeaker(false);
    setHandUpCount(0);

    // if (isShouldDisplayReasonForm) {
    //   openSetReasonModal();
    // }

    dispatchCall({
      type: CallActionTypes.END_CALL,
    });

    flag.current = false;
  }, []);

  const handleAccept = useCallback(() => {
    reserveCall();
    setCanStartCall(true);
    dispatch(setShouldDisplayReasonForm(true));
  }, [dispatch]);

  useEffect(() => {
    const normalizeDataByNullStartedAt = callState.othersCalls.filter((call) => call.started_at);

    const isNewIncomeIncludes = normalizeDataByNullStartedAt.some((el) => {
      return el.status === CallStatus.NEW && el.reason !== "callback";
    });

    if (isNewIncomeIncludes) {
      setIsCalling(true);
    } else {
      setIsCalling(false);
    }

    const shouldRing =
      callState?.selectedCall?.session_id?.length === 0 &&
      isVolumeOn &&
      isCalling &&
      !selectedGroupCall;

    if (callState.operatorStatus === InterpreterStatus.ONLINE && shouldRing) {
      playSound();
    } else {
      exposedData.stop();
    }
  }, [callState, isVolumeOn, isCalling, playSound, exposedData, selectedGroupCall]);

  useEffect(() => {
    if (!isAuth) return;
    CallService.getCallStats()
      .then(({ success, ...rest }) => {
        if (!success) {
          console.log(rest.message);
        } else {
          setStats((prev) => ({ ...prev, ...rest }));
        }
      })
      .catch(console.log);
    socket.current = io(process.env.REACT_APP_SOCKET_URL);
    socket.current.on("connect", () => {
      console.log("socket connected");
    });
    socket.current.on("interpreter:stats", (stats) => {
      console.log("stats : ", stats);
      setStats((prev) => ({ ...prev, ...stats }));
    });
    socket.current.on("interpreter-requests:request.new", (message) => {
      console.log("interpreter-requests:request.new", message);
    });
    socket.current.on("interpreter-calls:call.changed", (callChanged) => {
      console.log("call changed: ", callChanged);
      if (
        callChanged.reason === CallReason.CALLBACK &&
        callChanged.status === CallStatus.ANSWERED
      ) {
        const currentDate = Math.floor(Date.now() / 1000);
        const started_at = callChanged.started_at ?? currentDate;

        // dispatchCall({
        //   type: CallActionTypes.PUSH_NEW_SOCKET_CALL,
        //   payload: { ...callChanged, started_at: started_at },
        // });
        dispatchCall({
          type: CallActionTypes.ADD_PRIORITY_CALL,
          payload: { ...callChanged, started_at: started_at },
        });
      }

      // if (callChanged.status === CallStatus.ANSWERED) {
      //   dispatchCall({
      //     type: CallActionTypes.ADD_PRIORITY_CALL,
      //     payload: { ...callChanged },
      //   });
      //   dispatchCall({
      //     type: CallActionTypes.DELETE_CALL,
      //     payload: callChanged.session_id,
      //   });
      // }
      // if (callChanged.status === CallStatus.ENDED) {
      //   dispatchCall({
      //     type: CallActionTypes.PUSH_NEW_SOCKET_CALL,
      //     payload: callChanged,
      //   });
      //   dispatchCall({
      //     type: CallActionTypes.DELETE_PRIORITY_CALL,
      //     payload: callChanged.session_id,
      //   });
      // }

      switch (callChanged.status) {
        case CallStatus.LOCKED:
          dispatchCall({ type: CallActionTypes.UPDATE_CALL_ITEM, payload: callChanged });
          break;
        case CallStatus.ANSWERED:
          // if (callChanged.session_id === callState.selectedCall.session_id) { 
          //   dispatchCall({ type: CallActionTypes.UPDATE_CURRENT_CALL, payload: callChanged });
          // }
          dispatchCall({
            type: CallActionTypes.ADD_PRIORITY_CALL,
            payload: { ...callChanged },
          });
          dispatchCall({
            type: CallActionTypes.DELETE_CALL,
            payload: callChanged.session_id,
          });
          break;
        case CallStatus.ENDED:
          dispatchCall({
            type: CallActionTypes.PUSH_SOCKET_CALL_BY_STARTED_AT,
            payload: callChanged,
          });
          dispatchCall({
            type: CallActionTypes.DELETE_PRIORITY_CALL,
            payload: callChanged.session_id,
          });
          break;

        default:
          dispatchCall({ type: CallActionTypes.UPDATE_CALL_ITEM, payload: callChanged });
          break;
      }

      if (callChanged.interpreter_id === isAuth.id) {
        dispatchCall({ type: CallActionTypes.UPDATE_CURRENT_CALL, payload: callChanged });
      }
    });
    socket.current.on("interpreter-calls:call.created", (call) => {
      // if (!call.type) {
      if (call.reason === "callback") {
        return;
      }
      if (call.started_at) {
        console.log("interpreter-calls:call.created", call);
      }
      const currentDate = Math.floor(Date.now() / 1000);
      const started_at = call.started_at ?? currentDate;

      // const callToDispatch = { id: nanoid(), ...call, started_at: started_at };
      // TODO listen socket connection

      dispatchCall({
        type: CallActionTypes.PUSH_NEW_SOCKET_CALL,
        payload: { ...call, started_at: started_at },
      });
      // }
    });
    socket.current.on("interpreter-calls:call.redirected", (message) => {
      console.log("redirect message", message);
      if (message.interpreter_id !== isAuth.id) return;
      playSound();

      openPopup({
        id: message.session_id,
        text: t("call.redirectTitle", { client: message.client_name }),
        callback: () => {
          CallService.answerCall(message.session_id)
            .then(({ success, message: responseMessage }) => {
              console.log();
              if (!success) {
                throw new Error(responseMessage);
              }
              dispatchCall({
                type: CallActionTypes.PREVIEW_CALL,
                payload: { ...message },
              });
              dispatch(setUserReasonSelected(message.reason));
              dispatch(setSessionIdToSubmit(message.session_id));
              handleAccept();
            })
            .catch((e) => {
              console.log("interpreter-calls:call.redirected error", e);
            })
            .finally(() => {
              exposedData.stop();
            });
        },
      });
    });
    socket.current.on("interpreter-calls:connectproweb.group.call.created", (payload) => {
      if (payload.started_at) {
        console.log("interpreter-calls:connectproweb.group.call.created", payload);
      }
      const currentDate = Math.floor(Date.now() / 1000);
      const started_at = payload.started_at ?? currentDate;

      // const callToDispatch = { id: nanoid(), ...call, started_at: started_at };
      // TODO listen socket connection

      dispatchCall({
        type: CallActionTypes.PUSH_NEW_SOCKET_CALL,
        payload: { ...payload, started_at: started_at },
      });
    });
    socket.current.on("interpreter-calls:connectproweb.group.call.changed", (callChanged) => {
      console.log("call changed: ", callChanged);
      dispatchCall({ type: "UPDATE_CALL_ITEM", payload: callChanged });

      // if (
      //   callChanged.status !== CallStatus.NEW &&
      //   callChanged.status !== CallStatus.ENDED &&
      //   callChanged.interpreter_id === isAuth.id &&
      //   callChanged.type === CallTypes.CLIENT_CALL
      // ) {
      //   dispatch(setUserReasonSelected(callChanged.reason));
      //   dispatch(setSessionIdToSubmit(callChanged.session_id));
      // }

      if (callChanged.interpreter_id === isAuth.id) {
        dispatchCall({ type: CallActionTypes.UPDATE_CURRENT_CALL, payload: callChanged });
      }
    });
    return () => {
      socket.current.disconnect();
      activityChange(InterpreterStatus.OFFLINE);
    };
  }, []);

  // useEffect(() => {
  //   if (!currentInterpreter) return;
  //   // console.log("currentInterpreter", currentInterpreter);
  //   if (currentInterpreter.online === InterpreterStatus.OFFLINE) {
  //     activityChange(InterpreterStatus.ONLINE);
  //   }
  // }, [currentInterpreter]);

  // useEffect(() => {
  //   const auth_token = AuthService.getUser()?.token;
  //   if (!auth_token) return;
  //   // console.log("rerender call provider", currentTranslations);
  //   const interval = setInterval(async () => {
  //     const data = await CallService.getCalls();
  //     const normalizeDataByNullStartedAt = data.calls.filter((call) => call.started_at);

  //     const isNewIncomeIncludes = normalizeDataByNullStartedAt.some(
  //       (el) => el.status === CallStatus.NEW
  //     );
  //     // const isNewIncomeExist = hasMatchingId(data.calls, callState.incomingCalls);
  //     // const otherCalls = data.calls.filter((call) => call.status !== CallStatus.NEW);
  //     if (isNewIncomeIncludes) {
  //       setIsCalling(true);
  //       //setCanStartCall(true)
  //     } else {
  //       setIsCalling(false);
  //     }

  //     if (areArraysSimilarByStatus(previousCallsRef.current, normalizeDataByNullStartedAt)) {
  //       return;
  //     }

  //     previousCallsRef.current = normalizeDataByNullStartedAt;

  //     // if (isNewIncomeIncludes && !isNewIncomeExist) {
  //     //   dispatchCall({
  //     //     type: CallActionTypes.SET_INCOMING_CALLS,
  //     //     payload: data.calls.find((el) => el.status === CallStatus.NEW),
  //     //   });
  //     //   // setCanStartCall(true)
  //     // }

  //     dispatchCall({
  //       type: CallActionTypes.SET_OTHERS_CALLS,
  //       payload: [...normalizeDataByNullStartedAt],
  //     });
  //   }, 2500);

  //   return () => clearInterval(interval);
  // }, [callState]);
  // console.log('provider',publisher);
  useEffect(() => {
    if (!publisher.current) return;

    publisher.current.publishVideo(publisherVideo);
  }, [publisherVideo, publisher]);

  useEffect(() => {
    if (!publisher.current) return;

    publisher.current.publishAudio(publisherAudio);
  }, [publisherAudio, publisher]);

  useEffect(() => {
    const auth_token = AuthService.getUser()?.token;
    if (!auth_token) return;

    activityChange(InterpreterStatus.ONLINE);
    fetchCallsData();
  }, []);

  // useEffect(() => {
  //   const auth_token = AuthService.getUser()?.token;
  //   if (!auth_token) return;

  //   fetchCallsData();

  //   return () => clearInterval(intervalFetchDataCalls);
  // }, [intervalFetchDataCalls, fetchCallsData]);

  // const callSocket = useMemo(() => {
  //     if (!state.authenticated()) return
  //     return io(`${process.env.REACT_APP_SOCKET_URL}/call`, {
  //         path: '/v2',
  //         extraHeaders: {
  //             'x-interpreter-app-type': 'connectoperator',
  //             authorization: `Bearer ${AuthService?.getUser()?.token}`
  //         }
  //     })
  // }, [state.authenticated()])

  // const operatorSocket = useMemo(() => {
  //     if (!state.authenticated()) return
  //     return io(`${process.env.REACT_APP_SOCKET_URL}/operator`, {
  //         path: '/v2',
  //         extraHeaders: {
  //             'x-interpreter-app-type': 'connectoperator',
  //             authorization: `Bearer ${AuthService?.getUser()?.token}`
  //         }
  //     })
  // }, [state.authenticated()])

  // useEffect(() => {
  //     if (!callSocket || !operatorSocket) return

  //     callSocket.on('connect', () => {
  //         console.log('call socket connected', callSocket.id)
  //     })

  //     operatorSocket.on('connect', (payload) => {
  //         console.log('operator socket connect', operatorSocket.id)
  //     })
  //     operatorSocket.on('new:call:created', (response) => {
  //         console.log('new call create', response)
  //         const { apiKey, call, user } = response

  // const newIncoming = {
  //     sessionId: call.sessionId,
  //     token: call.token,
  //     receiverSocketId: user.socketId,
  //     user: user,
  //     startedAt: call.initiatedAt,
  //     reason: call.type === 'laptopToGroup' ? 'group' : call.reason,
  //     groupSessionId: call.groupSessionId,
  //     lang: call.language,
  //     call: call,
  //     apiKey: apiKey,
  //     type: call.type
  // }
  // dispatchCall({
  //     type: CallActionTypes.PREVIEW_CALL,
  //     payload: newIncoming
  // })
  // dispatchCall({
  //     type: CallActionTypes.SET_INCOMING_CALLS,
  //     payload: newIncoming
  // })
  //         setIsCalling(true)
  //     })

  //     operatorSocket.on('call:redirected', (response) => {
  //         console.log('call:redirected', response)
  //         const { apiKey, call, user } = response

  //         const newIncoming = {
  //             sessionId: call.sessionId,
  //             token: call.token,
  //             receiverSocketId: user.socketId,
  //             user: user,
  //             startedAt: call.initiatedAt,
  //             reason: call.type === 'laptopToGroup' ? 'group' : call.reason,
  //             groupSessionId: call.groupSessionId, // add if
  //             lang: call.language,
  //             call: call,
  //             apiKey: apiKey,
  //             type: call.type
  //         }

  //         dispatchCall({
  //             type: CallActionTypes.PREVIEW_CALL,
  //             payload: newIncoming
  //         })
  //         dispatchCall({
  //             type: CallActionTypes.SET_INCOMING_CALLS,
  //             payload: newIncoming
  //         })
  //         setIsCalling(true)
  //     })

  //     operatorSocket.on('operator:activities', (payload) => {
  //         console.log('operator activities ', payload)
  //     })
  //     operatorSocket.on('interpreter:activity:changed', (payload) => {
  //         console.log('operatorSocket1 activity ', payload)
  //         dispatchCall({ type: 'CHANGE_OPERATOR_STATUS', payload })
  //     })

  //     operatorSocket.on('call:booked', (payload) => {
  //         console.log('booking', payload)
  //         if (payload.status !== 'created') return
  //         setBookingModalInfo(payload)
  //     })
  //     operatorSocket.on('call:reserved', (response) => {
  //         console.log('call reserved', response)

  //         const replier = response?.listeners?.find(
  //             (el) => el.type === 'replier'
  //         )

  //         if (response?.call?.status === 'ended') {
  //             dispatchCall({
  //                 type: CallActionTypes.DELETE_CALL,
  //                 payload: response.call.sessionId
  //             })
  //         }

  //         if (AuthService?.getUser().email !== replier?.properties?.name) {
  //             dispatchCall({
  //                 type: CallActionTypes.DELETE_CALL,
  //                 payload: response.sessionId
  //             })
  //         }
  //     })

  //     operatorSocket.on('interpreters:statuses', (response) => {
  //         console.log('statuses', response)
  //         response.forEach((el) => {
  //             dispatchCall({ type: 'ADD_NEW_OPERATOR', payload: el })
  //         })
  //     })

  //     callSocket.on('disconnect', () => {
  //         console.log('call socket disconnect')
  //     })

  //     operatorSocket.on('disconnect', () => {
  //         console.log('operator socket disconnect')
  //     })

  //     callSocket.on('exception', (payload) => {
  //         console.log('CALL SOCKET ERROR: ', payload)

  //         if (payload.code === 401) {
  //             localStorage.removeItem(`${process.env.REACT_ENV_TYPE}_user`)
  //             window.location.reload()
  //         }
  //     })

  //     operatorSocket.on('exception', (payload) => {
  //         console.log('OPERATOR SOCKET ERROR: ', payload)

  //         if (payload.code === 401) {
  //             localStorage.removeItem(`${process.env.REACT_ENV_TYPE}_user`)
  //             window.location.reload()
  //         }
  //     })

  //     return () => {
  //         callSocket.disconnect()
  //         operatorSocket.disconnect()
  //     }
  // }, [])

  //   useEffect(() => {
  //     socket.current = io(process.env.REACT_APP_SOCKET_URL);
  //     const user = AuthService.getUser();
  //     //   console.log('user===>>',user);
  //     socket.current.on("connect", (socket) => {
  //       console.log("socket connected " + socket);
  //     });
  //     socket.current.on(`interpreter-requests:request.free`, (message) => {
  //       console.log(message);
  //     });
  //     socket.current.on(`interpreter.${user.id}:utog.ended`, (message) => {
  //       console.log(message);
  //       let question = `Решена ли проблема члена УТОГ ${message.name}?`;
  //     //   if (window.confirm(question)) {
  //     //     this.network.closeUtog(message.client_id);
  //     //   }
  //     });

  //     return () => {
  //       socket.current = null;
  //     };
  //   }, []);
  // console.log('session ref',sessionRef.current);

  // socket listeners for future
  // useEffect(() => {
  //   const user = AuthService.getUser();
  //   if (!user) return;
  //   const socketURL = process.env.REACT_APP_SOCKET_URL;
  //   console.log(`Connecting to socket at: ${socketURL}`);

  //   socket.current = io(socketURL);

  //   //console.log("Current user:", user);

  //   socket.current.on("connect", () => {
  //     console.log("Socket connected");
  //   });

  //   socket.current.on(`interpreter-requests:request.free`, (message) => {
  //     console.log("Received message:", message);
  //   });

  //   socket.current.on(`interpreter.${user.id}:utog.ended`, (message) => {
  //     console.log("UTOG Ended:", message);
  //     let question = `Решена ли проблема члена УТОГ ${message.name}?`;
  //     console.log(question);
  //   });

  //   // console.log("socket.current", socket.current);
  //   return () => {
  //     console.log("Cleaning up socket");
  //     socket.current.disconnect();
  //     socket.current = null;
  //   };
  // }, []);

  // const switchOperator = async (socketId) => {
  //   await sessionRef.current.sessionHelper.session.signal({
  //     data: "",
  //     type: "operatorCanRedirect",
  //   });

  //   const redirected = {
  //     type: "redirected",
  //     sessionId: sessionRef?.current?.props?.sessionId,
  //     disconnectedId: AuthService?.getUser()?.id,
  //     socketId,
  //     connectionId: publisher?.current?.session?.connection?.connectionId,
  //   };

  //   console.log(redirected);
  //   // callSocket.emit('call:handle:disconnect', redirected, (res) => {
  //   //     console.log('disconnected', res)
  //   //     dispatchCall({
  //   //         type: CallActionTypes.END_CALL
  //   //     })
  //   // })
  // };

  const reserveCall = () => {
    setIsCalling(false);

    dispatchCall({ type: CallActionTypes.TAKE_CALL });
    // const { apiKey, call, type, user } = callState.selectedCall;

    // const reservationObj = { apiKey, call, type, user };
    // callSocket.emit('call:reservation', reservationObj, (payload) => {
    //     console.log('reservation ', payload)

    // setIsCalling(false)
    // dispatchCall({ type: CallActionTypes.TAKE_CALL })
    // })
  };

  const activityChange = async (activity) => {
    const { success } = await InterpreterService.handleActivity(activity);

    if (success) {
      dispatchCall({
        type: CallActionTypes.SET_OPERATOR_STATUS,
        payload: activity,
      });
    }

    // operatorSocket.emit('activity:status', activity, (payload) => {
    //     console.log('activity change', payload)
    // dispatchCall({
    //     type: CallActionTypes.SET_OPERATOR_STATUS,
    //     payload: payload.status
    // })
    // })
  };

  return (
    <CallContext.Provider
      value={{
        callState,
        dispatchCall,
        publisher,
        // setPublisher,
        publisherVideo,
        setPublisherVideo,
        publisherAudio,
        setPublisherAudio,
        reserveCall,
        sessionRef,
        isVolumeOn,
        setIsVolumeOn,
        sessionData,
        bookingModalInfo,
        setBookingModalInfo,
        activityChange,
        chatMessages,
        setChatMessages,
        rightTabId,
        setRightTabId,
        canStartCall,
        setCanStartCall,
        streamAvailable,
        setStreamAvailable,
        subscribersToRender,
        setSubscribersToRender,
        isOffSpeaker,
        setIsOffSpeaker,
        handUpCount,
        setHandUpCount,
        handleAccept,
        endCall,
        flag,
        socket,
        stats,
        openSetReasonModal,
      }}
    >
      <ReasonModal isOpen={isOpenSetReason} handleClose={closeSetReasonModal} />
      {children}
    </CallContext.Provider>
  );
};
