export enum MessagesType {
  TEXT = "text",
  IMAGE = "image",
  VIDEO = "video",
  FILE = 'file'
}

export enum MessagesStatus {
  SENT = "sent",
  SUCCESS = "success",
  NOT_RECEIVED = "error",
}