import React, { useCallback, useEffect, useState } from "react";
import { OTSession } from "opentok-react";
import Lottie from "react-lottie";
import { Box } from "@material-ui/core";
import { useCallContext } from "../../reducers/Call/CallProvider";
import { CallActionTypes } from "../../reducers/Call/CallActionsTypes";
import Logo from "../../Logo";
import Loader from "../../assets/lottie/loader.json";
import { fullHeight } from "../../utils/constants";
import DeclineBtn from "../../assets/declineButton.svg";
import { useIsOpen, useVideoRecorder } from "src/hooks";
import CallChat from "./CallChat";
import { CallTypes, OpentokSignals } from "../../types";

import AsideTools from "./AsideTools";
import SwitchOperator from "src/components/SwitchOperator/SwitchOperator";
import CountdownTimer from "src/utils/CountdownTimer";
import { CallService } from "src/services/CallService";
import toast from "react-hot-toast";
import { EmergencyDictionary } from "./EmergencyDictionary";
import { CallbackModal } from "./CallbackModal";
import { Subscriber } from "./Subscriber";
import moment from "moment";

export const CallPage = () => {
  const [messages, setMessages] = useState([]);
  const { isOpen, toggle, close, open } = useIsOpen();
  const { isOpen: isOpenSwitchModal, close: closeSwitchModal, open: openSwitchModal } = useIsOpen();
  const { isOpen: isOpenDictionary, close: closeDictionary, open: openDictionary } = useIsOpen();
  const {
    isOpen: isOpenCallbackModal,
    close: closeCallbackModal,
    open: openCallbackModal,
  } = useIsOpen();

  const initialTimerValue = 10 * 60;
  // const newMessages = useRef(0);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [isCountdownStart, setIsCountdownStart] = useState(false);
  const [countdownTime, setCountdownTime] = useState(initialTimerValue);
  const { startRecording, stopRecording } = useVideoRecorder();
  const {
    callState,
    dispatchCall,
    publisher,
    sessionRef,
    canStartCall,
    streamAvailable,
    setStreamAvailable,
    subscribersToRender,
    setSubscribersToRender,
    endCall,
    flag,
    socket,
  } = useCallContext();

  const { selectedCall } = callState;

  const isGroupCall = selectedCall.reason === "group";

  const uniqueIds = new Set();

  const filteredSubscribers = subscribersToRender.filter((item) => {
    const isUnique = !uniqueIds.has(item.stream.id);
    uniqueIds.add(item.stream.id);
    return isUnique;
  });

  const handleDeclineCall = useCallback(() => {
    if (selectedCall.type === CallTypes.CONNECT_PRO_WEB_GROUP_CALL) {
      CallService.endGroupWebProCall(selectedCall.session_id);
    }
    dispatchCall({
      type: CallActionTypes.END_CALL,
    });
    setCountdownTime(initialTimerValue);

    sessionRef?.current?.sessionHelper.session.signal({ type: OpentokSignals.EndCall });
  }, [dispatchCall, initialTimerValue, sessionRef, selectedCall]);

  const sessionEventHandlers = {
    streamCreated: (event) => {
      setCountdownTime(initialTimerValue);
      setIsCountdownStart(false);

      listenMessages();

      const { stream } = event;

      const newUser = {
        stream,
        isCameraOn: stream.hasVideo,
        isAudioOn: stream.hasAudio,
        name: stream.name,
        isShowEndBtn: (isGroupCall && stream.name.includes("amsaanpro")) || !isGroupCall,
        isAmsaanPro: stream.name.includes("amsaanpro"),
        isSelectedUser: !isGroupCall || (isGroupCall && stream.name.includes("amsaanpro")),
      };

      setSubscribersToRender((prevState) => [...prevState, newUser]);

      setStreamAvailable(true);
      flag.current = true;
    },
    streamDestroyed: (event) => {
      console.log("Stream Destroyed", event);
      const userName = event.stream.name;
      const isGroupCall = selectedCall.reason === "group";

      setSubscribersToRender((prevState) =>
        prevState.filter((el) => el.stream.id !== event.stream.id)
      );

      if (userName.includes("amsaanpro") || !isGroupCall) handleDeclineCall();
    },
    onStreamsUpdated: (event) => {
      console.log("onStreamsUpdated", event);
    },
    streamPropertyChanged: (event) => {
      console.log("event streamPropertyChanged", event);
      const { stream } = event;

      const newUser = {
        stream,
        isCameraOn: stream.hasVideo,
        // isAudioOn: stream.hasAudio,
        name: stream.name,
        isShowEndBtn: (isGroupCall && stream.name.includes("amsaanpro")) || !isGroupCall,
        isAmsaanPro: stream.name.includes("amsaanpro"),
        isSelectedUser: !isGroupCall || (isGroupCall && stream.name.includes("amsaanpro")),
      };
      setSubscribersToRender((prevState) =>
        prevState.map((el) => (el.stream.id === event.stream.id ? { ...el, ...newUser } : el))
      );
    },
    sessionDisconnected: (event) => {
      console.log("sessionDisconnected", event);
      if (selectedCall.type === CallTypes.CLIENT_CALL) {
        stopRecording();
      }
    },
    connectionDestroyed: (event) => {
      console.log("connectionDestroyed", event);
    },
    connectionCreated: (event) => {
      console.log("connectionCreated", event);
    },
    signal: (event) => {
      console.log("signal event ", event);
      if (event.type === "signal:endCall") {
        handleDeclineCall();
      }
    },
  };
  // console.log("subscribersToRender", subscribersToRender);
  // console.log("OTSession, OTSubscriber", OTSession, OTSubscriber);

  useEffect(() => {
    if (!isCountdownStart) return;

    if (countdownTime === 0) {
      if (callState.selectedCall.call.reason === "callback") {
        toast.error("На жаль юзер не прийняв дзвінок");
      }
      handleDeclineCall();
      return;
    }

    const intervalId = setInterval(() => {
      setCountdownTime((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdownTime, dispatchCall, isCountdownStart, handleDeclineCall, callState.selectedCall]);

  useEffect(() => {
    if (!Boolean(callState.selectedCall.session_id?.length)) {
      endCall();
      setMessages([]);
      close();
      closeSwitchModal();
      closeDictionary();
      closeCallbackModal();
      setIsCountdownStart(false);
    }

    if (Boolean(callState.selectedCall.session_id?.length) && !streamAvailable) {
      setCountdownTime(60);
      setIsCountdownStart(true);
    }
  }, [callState.selectedCall, streamAvailable]);

  useEffect(() => {
    if (isOpen) return;
    if (messages.length === 1) {
      open();
    }
    if (messages.length) {
      setNewMessagesCount((prev) => (prev += 1));
    }
  }, [messages.length]);

  function listenMessages() {
    socket.current.on(
      "call-messages." + selectedCall.session_id + ":message.received",
      (message) => {
        // console.log(message);
        setMessages((prev) => [...prev, message]);
      }
    );
  }

  function handleToggleChatVisible() {
    toggle();
    setNewMessagesCount(0);
  }

  const handlePayAttention = React.useCallback(() => {
    sessionRef?.current?.sessionHelper.session.signal({ type: OpentokSignals.PayAttention });
  }, [sessionRef]);

  return (
    <Box
      width="100%"
      style={{
        verticalAlign: "center",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        display: "flex",
      }}
    >
      {!!selectedCall.session_id.length ? (
        <Box
          width="100%"
          height={fullHeight}
          style={{
            width: "100%",
            paddingRight: 48,
            position: "relative",
            // display: "grid",
            // gridTemplateColumns:
            //   isActiveAdditionalStream || isLoadingAdditionalCallStream
            //     ? "1fr 1fr 48px"
            //     : "1fr 48px",
          }}
        >
          <OTSession
            width={"100%"}
            ref={sessionRef}
            eventHandlers={{
              ...sessionEventHandlers,
              sessionDisconnected: (event) => {
                console.log("session disconnected", event);
                if (selectedCall.type === CallTypes.CLIENT_CALL) {
                  stopRecording();
                }
              },
            }}
            onConnect={async () => {
              canStartCall && sessionRef.current.sessionHelper.session.publish(publisher.current);
              if (selectedCall.type === CallTypes.CLIENT_CALL) {
                const publisherEl = await publisher.current.element;
                const videoPublisherEl = await publisherEl.querySelector(".OT_video-element");

                startRecording(
                  videoPublisherEl,
                  `operator-${selectedCall.session_id}-${moment
                    .unix(selectedCall.asnwered_at || selectedCall.started_at)
                    .format("YYYY-MM-DD HH:mm:ss")}`
                );
              }
            }}
            apiKey={selectedCall.api_key}
            sessionId={selectedCall.session_id}
            token={selectedCall.token}
            onSignalRecieve={(data) => {
              console.log("data recieve", data);
            }}
            key={selectedCall.session_id + selectedCall.token}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                height: fullHeight,
                width: "100%",
              }}
            >
              {filteredSubscribers.map((subscriber) => (
                <Subscriber
                  countdownTime={countdownTime}
                  handleDeclineCall={handleDeclineCall}
                  isCountdownStart={isCountdownStart}
                  selectedCall={selectedCall}
                  sessionRef={sessionRef}
                  setIsCountdownStart={setIsCountdownStart}
                  setSubscribersToRender={setSubscribersToRender}
                  subscriber={subscriber}
                  key={subscriber.stream.id}
                  handlePayAttention={handlePayAttention}
                />
              ))}
            </div>
          </OTSession>
          {streamAvailable ? null : (
            <Box
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                top: "calc(-100vh + 40px)",
              }}
            >
              <Lottie
                options={{
                  animationData: Loader,
                  loop: true,
                  autoplay: true,
                }}
              />

              {callState.selectedCall?.call?.reason === "callback" && (
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    position: "absolute",
                    bottom: 12,
                    left: "50%",
                    transform: "translate(-50%,0)",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {isCountdownStart ? (
                    <CountdownTimer
                      countdownTime={countdownTime}
                      options={{ minutes: true, seconds: true }}
                    />
                  ) : null}

                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={handleDeclineCall}
                  >
                    <img src={DeclineBtn} alt="Decline" />
                  </button>
                </div>
              )}
            </Box>
          )}
          <AsideTools
            wrapperStyle={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "#fff",
              borderLeft: "1px solid #eee",
            }}
            newMessagesCount={newMessagesCount}
            setChatVisible={handleToggleChatVisible}
            setSwitchOperatorVisible={openSwitchModal}
            setDictionaryVisible={openDictionary}
            setCallbackModalVisible={openCallbackModal}
          />
          <CallChat
            sessionId={selectedCall.session_id}
            isVisible={isOpen}
            messages={messages}
            setIsVisible={handleToggleChatVisible}
          />
          <SwitchOperator isOpen={isOpenSwitchModal} setIsOpen={closeSwitchModal} />
          <EmergencyDictionary isVisible={isOpenDictionary} setIsVisible={closeDictionary} />
          <CallbackModal isVisible={isOpenCallbackModal} setIsVisible={closeCallbackModal} />
        </Box>
      ) : (
        <Logo />
      )}
    </Box>
  );
};
