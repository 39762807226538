import React from "react";
import symbolDefs from "src/assets/symbol-defs.svg";

export enum IconName {
  PARTNER_CALL = "widget",
  CLIENT_CALL = "callIcon",
  CONNECT_PRO_CALL = "tablet",
  CONNECT_PRO_WEB_CALL = "connect-pro-web",
  WIDGET_CALL = "qrCode",
  QR_CALL = "qrCode",
  SEND_ICON = "sendIcon",
  CROSS = "cross",
  LOGO = "logo",
  REDIRECT = "redirect",
  CHAT = "chat",
  CONNECT_PRO_WEB_GROUP_CALL = "connect-pro-web",
  DEAF_ID = "deaf-id",
  DICTIONARY = "dictionary",
  TIME = "time",
  CHECK = "check",
}

export interface IconProps {
  name: IconName;
  color?: string;
  width?: string;
  height?: string;
  testID?: string;
  size?: string;
}

export const Icon: React.FC<IconProps> = ({
  name,
  width = "24px",
  height = "24px",
  color = "black",
  size,
}) => {
  return (
    <svg height={size ? size : height} width={size ? size : width} color={color}>
      {React.createElement("use", {
        href: `${symbolDefs}#${name}`,
        xlinkHref: `${symbolDefs}#${name}`,
      })}
    </svg>
  );
};
