import { Platform } from "src/types";

export enum Feature {
  PAY_ATTENTION = "pay_attention",
  CALLBACK = "callback",
}

interface FeatureMap {
  [version: string]: Feature[];
}

export const featureDetector = (appVersion: string | undefined, platform: Platform | undefined) => {
  const availableFeatures: Feature[] = [];

  if (!appVersion || !platform) {
    console.warn("Both appVersion and platform are required.");
    return {
      platform,
      appVersion,
      availableFeatures,
    };
  }

  const featureMap: Record<Platform, FeatureMap> = {
    ios: {
      "4.6.6": [Feature.PAY_ATTENTION, Feature.CALLBACK],
    },
    android: {
      "4.6.8": [Feature.PAY_ATTENTION, Feature.CALLBACK],
    },
  };

  if (!featureMap[platform]) {
    console.warn(`Unsupported platform: ${platform}`);
    return {
      platform,
      appVersion,
      availableFeatures: availableFeatures,
    };
  }

  const availableVersions = Object.keys(featureMap[platform]);
  const latestVersion = availableVersions[availableVersions.length - 1];

  const matchedVersion =
    availableVersions.find((version) => version === appVersion) || latestVersion;

  return {
    platform,
    appVersion,
    availableFeatures: featureMap[platform][matchedVersion] || [],
  };
};
