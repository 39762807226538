import libphonenumber, { CountryCode } from "libphonenumber-js";

export const formatPhoneNumber = ({
  input,
  defaultCountryCode = "+38",
  defaultCountry = "UA",
}: {
  input: string;
  defaultCountryCode?: string;
  defaultCountry?: CountryCode;
}): string | null => {
  if (!input.trim()) return null;

  // Remove all non-numeric characters except leading '+'
  let cleaned = input.replace(/[^\d+]/g, "");

  // If no country code, add the default
  if (!cleaned.startsWith("+")) {
    cleaned = `${defaultCountryCode}${cleaned}`;
  }

  // Validate and format using libphonenumber
  const parsed = libphonenumber(cleaned, { defaultCountry });
  return parsed?.isValid() ? parsed.format("E.164") : null;
};
