import { useRef, useState } from "react";
import { BlobWriter, ZipWriter } from "@zip.js/zip.js";

export const useVideoRecorder = () => {
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recording, setRecording] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const createPasswordProtectedZip = async (
    fileData: Uint8Array,
    fileName: string,
    password: string
  ): Promise<Blob> => {
    const blobWriter = new BlobWriter("application/zip");
    const zipWriter = new ZipWriter(blobWriter, { password,level:9, });

    const stream = new Response(fileData).body;
    if (stream) {
      await zipWriter.add(fileName, stream);
    } else {
      throw new Error("Failed to create stream from file data.");
    }

    await zipWriter.close();
    return blobWriter.getData();
  };

  const startRecording = async (videoElement: HTMLVideoElement, fileName?: string) => {
    const password = "470475";

    if (!videoElement) {
      setError("Invalid video element provided.");
      return;
    }

    try {
      const mediaStream = (videoElement as any).captureStream(30);
      if (!mediaStream) throw new Error("Video capture stream is unavailable.");

      const options = { mimeType: "video/webm; codecs=vp9", videoBitsPerSecond: 500_000 };
      const mediaRecorder = new MediaRecorder(mediaStream, options);
      const recordedChunks: Blob[] = [];

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          recordedChunks.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        if (recordedChunks.length === 0) {
          setError("No data recorded.");
          return;
        }

        const blob = new Blob(recordedChunks, { type: "video/webm" });
        const arrayBuffer = await blob.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        const finalFileName = fileName ? `${fileName}.webm` : `video-recording-${Date.now()}.webm`;

        try {
          const zipBlob = await createPasswordProtectedZip(uint8Array, finalFileName, password);

          const zipUrl = URL.createObjectURL(zipBlob);
          const a = document.createElement("a");
          a.href = zipUrl;
          a.download = `${finalFileName}.zip`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } catch (zipError) {
          console.error("Error creating ZIP:", zipError);
          setError("Failed to create ZIP file.");
        }
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
      setRecording(true);
      setError(null);
      console.log("Video recording started");
    } catch (error) {
      console.error("Error starting video recording:", error);
      setError("An error occurred while starting the recording.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      console.log("Video recording stopped");
    }
  };

  return { startRecording, stopRecording, recording, error };
};
