import { Admission } from "src/types";
import { URI } from "../constants/defaultConstants";
import { AuthService } from "./AuthService";
import { RequestService } from "./RequestService";

export const AdmissionService = {
  async getAdmissions({ page = 1, perPage = 10 }: { page: number; perPage?: number }) {
    const token = AuthService.getUser().token;
    if (!token) return null;

    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": token,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` as string,
        },
      }).getRequest(URI.admissions, { params: { page, perPage } });

      return response;
    } catch (error) {
      console.error("getAdmissions: ", error);
      return null;
    }
  },
  async getNewAdmissions({ page = 1, perPage = 10 }: { page: number; perPage?: number }) {
    const token = AuthService.getUser().token;
    if (!token) return null;

    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": token,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` as string,
        },
      }).getRequest(URI.newAdmissions, { params: { page, perPage } });

      return response;
    } catch (error) {
      console.error("getNewAdmissions: ", error);
      return null;
    }
  },
  async getFilteredAdmissions({
    first_name,
    last_name,
    patronymic,
  }: {
    last_name: string;
    first_name: string;
    patronymic: string;
  }) {
    try {
      const response = await RequestService({
        data: { first_name, last_name, patronymic },
      }).postRequest(URI.getFilteredAdmissions);

      return response;
    } catch (error) {
      console.error("getFilteredAdmissions: ", error);
      return null;
    }
  },
  async getFilteredAdmissionsByPhone({ phone }: { phone: string }) {
    try {
      const response = await RequestService({
        data: { phone },
      }).postRequest(URI.getFilteredAdmissionsByPhone);

      return response;
    } catch (error) {
      console.error("getFilteredAdmissionsByPhone: ", error);
      return null;
    }
  },
  async getAdmissionById(id: number) {
    const token = AuthService.getUser().token;
    if (!token) return null;

    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": token,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` as string,
        },
      }).getRequest(URI.admissions + `/${id}`);

      return response;
    } catch (error) {
      console.error("getAdmissions: ", error);
      return null;
    }
  },
  async deleteAdmissionById(id: number) {
    const token = AuthService.getUser().token;
    if (!token) return null;

    try {
      const response = await RequestService({
        data: {},
        headers: {
          "X-Interpreter-Token": token,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` as string,
        },
      }).deleteRequest(URI.admissions + `/${id}`);

      return response;
    } catch (error) {
      console.error("getAdmissions: ", error);
      return null;
    }
  },
  async patchAdmissionById(id: number, data: Partial<Admission>) {
    const token = AuthService.getUser().token;
    if (!token) return null;

    try {
      const response = await RequestService({
        data,
        headers: {
          "X-Interpreter-Token": token,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` as string,
        },
      }).patchRequest(URI.admissions + `/${id}`);

      return response;
    } catch (error) {
      console.error("getAdmissions: ", error);
      return null;
    }
  },
};
