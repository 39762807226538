import React from "react";
import { Transition } from "react-transition-group";

import { Box, Typography, Tabs, Tab, Paper, createStyles } from "@material-ui/core";
import {
  AccountCircleOutlined,
  Group,
  InsertDriveFileOutlined,
  PhoneOutlined,
} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import { useCallContext } from "../reducers/Call/CallProvider";
import { Publisher } from "./Publisher";
import { TabIncoming } from "./TabIncoming";
import { TabContacts } from "./TabComponents/TabContacts";
import { TabWrapper } from "./TabWrapper";
import { fullHeight } from "../utils/constants";
import { TabTranslations } from "./TabComponents/TabTranslations";
import { useDispatch, useSelector } from "react-redux";
import { switchPage } from "../reducers/Pagination/PaginationSlice";
import { useTranslation } from "react-i18next";
import AudioCallOff from "../assets/volume-off.svg";
import AudioCallOn from "../assets/volume-on.svg";

import { MessagesStatus, RoutesMap } from "src/types";
import { GroupTab } from "src/pages/GroupCallPage/GroupTab";
import { AdmissionTab } from "src/pages/AdmissionPage";
import { selectAllGroupCalls } from "src/reducers/GroupCall";
import { selectReadyToRecord } from "src/reducers/VideoRecorder/VideoRecorderSlice.selectors";
import VideoRecorderComponent from "./VideoRecorder";
import { useActions } from "src/hooks/useActions";
import {
  clearRecorderState,
  disactivateReadyToRecord,
} from "src/reducers/VideoRecorder/VideoRecorderSlice";
import { TranslationsService } from "src/services/TranslationsService";

// animation staff
const duration = 1200;
const defaultStyle = { transition: `all ${duration}ms ease-in-out` };
const transitionStyles = {
  entering: { paddingTop: 0 },
  entered: { paddingTop: 0 },
  exiting: { paddingTop: 0 },
  exited: { paddingTop: 0 },
};

const RenderItem = (item, stats) => {
  const { tabIcon, dialItem, tabItem, animatedItem } = useStyles();
  const groupCalls = useSelector(selectAllGroupCalls);

  const { t } = useTranslation();

  const Icon = !!item?.label?.length ? (
    <item.Icon className={tabIcon} />
  ) : (
    <Box className={dialItem}>
      <item.Icon className={tabIcon} />
    </Box>
  );

  return (
    <Tab
      key={item.id}
      disabled={item.disabled}
      label={
        <>
          <Typography variant="button">{t("routes." + item.route)}</Typography>
          {stats > 0 ? (
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                fontSize: 12,
                lineHeight: "100%",
                top: 0,
                right: 0,
                padding: 4,
                borderRadius: 50,
                backgroundColor: "red",
                color: "white",
                width: 22,
                height: 22,
              }}
            >
              {stats}
            </Typography>
          ) : null}
        </>
      }
      icon={Icon}
      className={`${tabItem} ${
        item.name === "groups" && groupCalls.some((el) => el.status === "new") ? animatedItem : ""
      }`}
      value={item.id}
    />
  );
};

const tabItems = [
  { route: RoutesMap.CALLS, Icon: PhoneOutlined, id: 0, name: "calls" },
  { route: RoutesMap.CONTACTS, Icon: AccountCircleOutlined, id: 1 },
  {
    route: RoutesMap.TRANSLATIONS,
    Icon: InsertDriveFileOutlined,
    id: 2,
    name: "requests",
  },
  {
    route: RoutesMap.GROUP_CALLS,
    Icon: Group,
    id: 3,
    name: "groups",
  },
  {
    route: RoutesMap.ADMISSIONS,
    Icon: Group,
    id: 4,
    name: "deaf",
  },
];

let timeout;

export const TabsPanel = () => {
  // const [value, setValue] = useState(3);
  // const [authState, dispatch] = useContext(AuthContext);
  const dispatch = useDispatch();
  const { page } = useSelector((state) => state.paginationReducer);
  // const { isCameraEnabled } = useSelector((state) => state.recordsReducer);
  const { callState, isVolumeOn, setIsVolumeOn, stats } = useCallContext();
  // const styles = useStyles();
  const { addMessages, clearDetailedImage } = useActions();

  const muteSoundHandler = () => {
    if (timeout) clearTimeout(timeout);
    if (isVolumeOn) {
      setIsVolumeOn(false);
      timeout = setTimeout(() => {
        setIsVolumeOn(true);
      }, 180000);
    } else {
      setIsVolumeOn(true);
    }
  };

  const handleChange = (event, newValue) => {
    // console.log("event tabs", event.value);
    if (event.value !== 2) {
      dispatch(disactivateReadyToRecord());
    }
    dispatch(switchPage(newValue));
    // console.log(newValue)
  };

  const { selectedCall, incomingCalls } = callState;

  // const isVideoActive = incomingCalls.length || selectedCall.session_id.length
  const isVideoActive = Boolean(incomingCalls?.length) || Boolean(selectedCall?.session_id?.length);
  const selectedIsReadyToRecord = useSelector(selectReadyToRecord);
  const { selectedId } = useSelector((state) => state.translationsReducer);
  const handleSubmitVideo = React.useCallback(
    async (form, setProgressBar, file) => {
      await TranslationsService.sendRequestVideo(selectedId, form, setProgressBar)
        .then((data) => {
          // console.log("TranslationsService.sendRequestVideo", data);
          if (data.success) {
            addMessages({
              ...data.request_message,
              content: URL.createObjectURL(file),
              video_status: MessagesStatus.SENT,
            });
          }
        })
        .finally(() => {
          dispatch(clearRecorderState());
          dispatch(disactivateReadyToRecord());
          clearDetailedImage();
        });
    },
    [selectedId, dispatch]
  );

  return (
    <Box
      style={{
        width: 420,
        margin: 0,
        paddingTop: 0,
        height: fullHeight,
        overflowY: "clip",
      }}
    >
      <Box flexDirection="row" display="flex" height="100%">
        <Box
          display="flex"
          width={64}
          bgcolor="#008DFF"
          flexDirection="column"
          color="white"
          height="100%"
        >
          {/* <Publisher /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 64,
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
              marginTop: 265,
            }}
          >
            <Tabs
              selectionFollowsFocus={true}
              orientation="vertical"
              variant="scrollable"
              value={Number(page)}
              onChange={handleChange}
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  width: 64,
                  // height: 70,
                  zIndex: 1,
                  backgroundColor: "white",
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                  overflowY: "auto",
                  // marginTop: 265,
                },
              }}
            >
              {tabItems.map((el) => {
                return RenderItem(el, stats[el.name]);
              })}
            </Tabs>

            <Box
              style={{
                width: 42,
                height: 42,
                borderRadius: "50%",
                backgroundColor: "#9C9C9C",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginBottom: 20,
              }}
            >
              <img
                onClick={muteSoundHandler}
                alt="mute button"
                src={isVolumeOn ? AudioCallOff : AudioCallOn}
                style={{
                  width: 25,
                  height: 25,
                }}
              />
            </Box>
          </div>
        </Box>
        {/* ---------------------------------------------- */}
        <Transition in={!isVideoActive} timeout={duration}>
          {(state) => (
            <Box
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
                display: "grid",
                gridTemplateRows: "265px 1fr",
                // marginTop: 265,
              }}
              display="inline-block"
            >
              {selectedIsReadyToRecord ? (
                <VideoRecorderComponent
                  wrapperButtons={{
                    position: "absolute",
                    backgroundColor: "#fff",
                    bottom: 2,
                    borderRadius: 4,
                    flexDirection: "row",
                    left: "50%",
                    transform: "translateX(-50%)",
                    maxWidth: 200,
                    width: "auto",
                    padding: 4,
                    alignItems:'center'
                  }}
                  wrapperStyles={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    padding: 0,
                    gap: 0,
                    display: "flex",
                  }}
                  onSubmit={handleSubmitVideo}
                  videoFormatForSubmit="mp4"
                />
              ) : (
                <Publisher />
              )}

              {/* <TabPanel value={page} index={0}>
								<PhoneInput />
							</TabPanel> */}

              <TabPanel value={page} index={0}>
                <TabIncoming />
              </TabPanel>
              <TabPanel value={page} index={1}>
                <TabContacts />
              </TabPanel>
              <TabPanel value={page} index={2}>
                <TabTranslations />
              </TabPanel>
              <TabPanel value={page} index={3}>
                <GroupTab />
              </TabPanel>
              <TabPanel value={page} index={4}>
                <AdmissionTab />
              </TabPanel>
            </Box>
          )}
        </Transition>
      </Box>
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const [calculatedHeight, setCalculatedHeight] = React.useState(0);

  // React.useEffect(() => {
  //   const calculateHeight = (e) => {
  //     const viewportHeight = window.innerHeight;

  //     const zoomLevel = window.innerWidth / window.outerWidth;
  //     const zoomRanges = [
  //       { max: 1.1, offset: 0.04 },
  //       { max: 1.13, offset: -0.005 },
  //       { max: 1.25, offset: -0.05 },
  //       { max: 1.32, offset: -0.1 },
  //       { max: 1.49, offset: -0.075 },
  //       { max: 1.75, offset: -0.12 },
  //       { max: 2, offset: -0.195 },
  //     ];

  //     let offset = 0;
  //     for (let range of zoomRanges) {
  //       if (zoomLevel <= range.max) {
  //         offset = viewportHeight * range.offset;
  //         break;
  //       }
  //     }

  //     // let offset = 0;

  //     // if ( zoomLevel <= 1.1) {
  //     //   offset = viewportHeight * 0.04;
  //     // } else if (zoomLevel > 1 && zoomLevel <= 1.13) {
  //     //   offset = viewportHeight * -0.005;
  //     // } else if (zoomLevel > 1.13 && zoomLevel <= 1.25) {
  //     //   offset = viewportHeight * -0.05;
  //     // } else if (zoomLevel > 1.25 && zoomLevel <= 1.32) {
  //     //   offset = viewportHeight * -0.1;
  //     // } else if (zoomLevel > 1.33 && zoomLevel <= 1.49) {
  //     //   offset = viewportHeight * -0.075;
  //     // } else if (zoomLevel > 1.49 && zoomLevel <= 1.75) {
  //     //   offset = viewportHeight * -0.11;
  //     // }

  //     const heightInPixels = viewportHeight - offset;

  //     setCalculatedHeight(heightInPixels);
  //   };

  //   // Calculate height initially and on window resize
  //   calculateHeight();
  //   window.addEventListener("resize", calculateHeight);

  //   // Cleanup event listener on unmount
  //   return () => window.removeEventListener("resize", calculateHeight);
  // }, []);
  // console.log("calculatedHeight", calculatedHeight);

  return (
    <Paper
      square
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        height: "100%",
        overflowY: "auto",
        ...(index === 4 ? { paddingBottom: 80 } : {}),
        // height: `${calculatedHeight}px`, // Dynamically calculated height
      }}
      {...other}
    >
      <TabWrapper value={value}>{value === index && children}</TabWrapper>
    </Paper>
  );
}

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <Paper
//       square
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       style={{
//         height: fullHeight,
//         // height: 'calc(100vh - 266px)',
//       }}
//       {...other}
//     >
//       <TabWrapper value={value}>{value === index && children}</TabWrapper>
//     </Paper>
//   );
// }

const useStyles = makeStyles((theme) =>
  createStyles({
    tabItem: {
      minWidth: 15,
      // height: 64,
      zIndex: 2,
      color: "#fff",
    },
    dialItem: {
      width: 40,
      height: 40,
      borderRadius: 40,
      backgroundColor: "#fff",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      color: "#008DFF",
    },
    tabIcon: {
      width: 24,
      height: 24,
      margin: "0px 20px 0px 20px",
    },
    animatedItem: {
      animation: `$call 500ms steps(1) infinite`,
    },
    "@keyframes call": {
      "0%": {
        backgroundColor: "#FFE87C",
      },
      "50%": {
        backgroundColor: "rgb(0, 141, 255)",
      },
      "100%": {
        backgroundColor: "#FFE87C",
      },
    },
  })
);
