import { reasonTypes } from "src/pages/CallPage/types";
import { CallStatus } from "./CallStatus";
import { Platform } from "./Platform";

interface Minute {
  id: number;
  type: string;
  status: string;
  order_id: number | null;
  ordered: number;
  client_id: number;
  left: number;
  active_till: string;
  created_at: string;
  updated_at: string;
}

interface CallReasonTranslation {
  id: number;
  call_reason_id: number;
  language: string;
  translation: string;
  created_at: string;
  updated_at: string;
}

interface CallReason {
  id: number;
  slug: string;
  created_at: string;
  updated_at: string;
  priority: number;
  is_visible: boolean;
  translations: CallReasonTranslation[];
}

interface CallExtra {
  device: string;
}

interface Interpreter {
  id: number;
  email: string;
  name: string;
  auth_token: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
  aem_staff_id: number | null;
  online: string;
}

export enum CallTypes {
  CLIENT_CALL = "CLIENT_CALL",
  CONNECT_PRO_CALL = "CONNECT_PRO_CALL",
  PARTNER_CALL = "PARTNER_CALL",
  WIDGET_CALL = "WIDGET_CALL",
  QR_CALL = "QR_CALL",
  CONNECT_PRO_WEB_CALL = "CONNECT_PRO_WEB_CALL",
  CONNECT_PRO_WEB_GROUP_CALL = "CONNECT_PRO_WEB_GROUP_CALL",
}

interface CallDetails {
  id: number;
  client_id: number;
  interpreter_id: number;
  device_id: number;
  status: string;
  started_at: string;
  answered_at: string | null;
  ended_at: string | null;
  locked_at: string;
  reason: string;
  session_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  other_reason: string | null;
  extra: CallExtra;
  region: string | null;
  app_version?: string;
  platform?: Platform;
  latest_market_version?: string;
  r_reason: CallReason;
  interpreter: Interpreter;
  connect_pro_web_client_id?: number;
}

export interface CallDataType {
  name: string;
  client_name: string | null;
  client_phone: string;
  display_reason: string;
  reason: reasonTypes;
  session_id: string;
  started_at: number;
  answered_at: number | null;
  ended_at: number;
  locked_at: number | null;
  status: CallStatus;
  rate: number;
  review: string;
  unlim: boolean;
  tags: string[];
  gender: string | null;
  date_of_birth: string | null;
  social_unity: boolean;
  minutes: Minute[];
  type: CallTypes;
  interpreter_id: number | null;
  commented: boolean;
  note: string | null;
  id: number;
  call: CallDetails;
  deaf_id: null | string;
  client_id: number;
  interpreter?: string;
}
